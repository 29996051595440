import React, { useEffect, useState } from 'react'
import Sidebar from './components/Sidebar'
import Connect from './components/Connect'
import { Form, Button, Table } from 'react-bootstrap'
import { toast } from 'react-toastify';

const Admins = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [admins, setAdmins] = useState('')
    const [isLoading, setIsloading] = useState(true)
    const [isButtonClicked, setIsButtonClicked] = useState(false);

    let btx = 0
    useEffect(() => {
        const getDashboard = async () => {
            try {
                const req = await Connect.get(`admin/admins`)
                const res = req.data;
                setAdmins(res.admins)
                setIsloading(false)
            } catch (err) {
                console.log(err.message)
            }
        }
        getDashboard()
    }, [btx])

    const handleSubmit = async () => {
        try {
            if (name === '' || email === '' || password === '') {
                toast('All fields are required', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return false
            }
            setIsButtonClicked(true);
            const data = {
                name,
                email,
                password
            }
            const resp = await Connect.post('admin/admins', data)
            const res = resp.data
            setAdmins(res.admins)
            setName(''); setEmail(''); setPassword('');
            setIsButtonClicked(false);
            toast(res.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (err) {
            console.log(err.message)
        }
    }
    const deleteAdmin = async (id) => {
        try {
            const idx = localStorage.getItem('daimo_admin')
            if (idx === id) {
                toast('Sorry, you are not allowed to delete your account. Ask a different admin for assistance.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return false
            }
            const req = await Connect.get(`admin/admin/delete/${id}`)
            const res = req.data;
            if (res.resCode === 200) {
                setAdmins(res.admins)
            }
            toast(res.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setIsloading(false)
        } catch (err) {
            console.log(err.message)
        }
    }
    return (
        <div className='containers'>
            <Sidebar />
            <div className='main'>
                <div className='titles'>Administrators</div>
                <div className='medium-space'></div>
                <div className='medium-space'></div>
                <div className='row'>
                    <div className='col-md-4 col-sm-6 col-xs-12'>
                        <Form>
                            <Form.Group controlId="formBasicPrice">
                                <Form.Label><i className='fa fa-user'></i> Fullname</Form.Label>
                                <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Fullname" />
                            </Form.Group>
                            <Form.Group controlId="formBasicPrice">
                                <Form.Label><i className='fa fa-envelope'></i> Email</Form.Label>
                                <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
                            </Form.Group>
                            <Form.Group controlId="formBasicPrice">
                                <Form.Label><i className='fa fa-lock'></i> Password</Form.Label>
                                <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
                            </Form.Group>
                            <div className='medium-space'></div>
                            <div style={{ textAlign: 'center', padding: 15 }}><Button variant="primary" className='bbtn' type="button" onClick={() => handleSubmit()} disabled={isButtonClicked}>
                                Create
                            </Button></div>
                        </Form>
                    </div>
                    <div className='col-md-8 col-sm-6 col-xs-12'>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ? <div>Loading...</div> : admins.map((item) => (
                                    <tr key={item._id}>
                                        <td>{item.name}</td>
                                        <td>{item.email}</td>
                                        <td><span className='butn' style={{ cursor: 'pointer' }} onClick={() => deleteAdmin(item._id)}>Delete</span></td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Admins