import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css'
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { ToastContainer } from 'react-toastify';

const Root = () => (
  <Router>
    <ToastContainer />
    <App />
  </Router>
)

ReactDOM.render(
  <Root />,
  document.getElementById('root')
);