import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { toast } from 'react-toastify';
import Connect from './components/Connect'

//COmponents
import logo from '../assets/images/logo.png'

const Forgot = () => {
    const history = useNavigate()
    const [email, setEmail] = useState('')

    useEffect(() => {
        const getDashboard = async () => {
            try {
                const id = localStorage.getItem('daimo_admin')
                const req = await Connect.get(`admin/profile/${id}`)
                const res = req.data;
                if (res.resCode === 200) {
                    history('/dashboard')
                }
            } catch (err) {

            }
        }
        getDashboard()
    }, [history])
    const handleSubmit = async () => {
        try {
            const data = {
                email
            }
            const req = await Connect.post('admin/forgot-password', data)
            const res = req.data
            toast(res.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            if (res.resCode === 200) {
                history('/reset-password')
            }
        } catch (err) {
            toast(err.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    return (
        <div className="container">
            <div className="row justify-content-center align-items-center vh-100">
                <div className="col-md-4 col-sm-12 col-xs-12" style={{ boxShadow: '0 0 10px #999', padding: 20 }}>
                    <div style={{ textAlign: 'center', padding: 40 }}><img src={logo} alt='Ton Accelerator' width={120} /></div>
                    <Form>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label><i className='fa fa-user'></i> Email</Form.Label>
                            <Form.Control type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter Email" />
                        </Form.Group>
                        <div style={{ textAlign: 'center', padding: 15 }}><Button variant="primary" className='bbtn' type="button" onClick={() => handleSubmit()}>
                            Procceed
                        </Button></div>
                        <div className='small-space'></div>
                        <div style={{ textAlign: 'center' }}>Back to <strong>Daimo administrator</strong> <Link to='/'>Login</Link></div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default Forgot