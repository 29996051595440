import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';

const Logout = () => {
    const history = useNavigate()
    useEffect(() => {
        const getDashboard = () => {
            localStorage.removeItem('token')
            localStorage.removeItem('daimo_admin')
            toast('Logout successful', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            history('/')
        }
        getDashboard()
    }, [history])
    return (
        <div>Logout</div>
    )
}

export default Logout