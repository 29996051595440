import React, { useEffect, useState } from 'react'
import Sidebar from './components/Sidebar'
import Connect from './components/Connect'
import { Form, Button, Table } from 'react-bootstrap'
import { toast } from 'react-toastify';

const Prices = () => {
    const [dash, setDash] = useState()
    const [isLoading, setIsloading] = useState(true)
    const [amount, setAmount] = useState('')
    const [duration, setDuration] = useState('')
    const [isButtonClicked, setIsButtonClicked] = useState(false);

    let btx = 0
    useEffect(() => {
        const getDashboard = async () => {
            try {
                const req = await Connect.get(`admin/prices`)
                const res = req.data;
                setDash(res.prices)
                setIsloading(false)
            } catch (err) {
                console.log(err.message)
            }
        }
        getDashboard()
    }, [btx])

    const handleSubmit = async (e) => {
        try {
            if (amount === '' || duration === '') {
                toast('All fields are required', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return false
            }
            setIsButtonClicked(true);
            const data = {
                value: amount,
                duration
            }
            const resp = await Connect.post('admin/prices', data)
            const res = resp.data
            setDash(res.prices)
            setIsButtonClicked(false);
            toast(res.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (err) {
            console.log(err.message)
        }
    }
    const deletePrice = async (id) => {
        try {
            const req = await Connect.get(`admin/prices/delete/${id}`)
            const res = req.data;
            if(res.resCode === 200){
                setDash(res.prices)
            }
            toast(res.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setIsloading(false)
        } catch (err) {
            console.log(err.message)
        }
    }
    return (
        <div className='containers'>
            <Sidebar />
            <div className='main'>
                <div className='titles'>Subscription Prices</div>
                <div className='medium-space'></div>
                <div className='medium-space'></div>
                <div className='row'>
                    <div className='col-md-4 col-sm-6 col-xs-12'>
                        <Form>
                            <Form.Group controlId="formBasicPrice">
                                <Form.Label><i className='fa fa-dollar-sign'></i> Price</Form.Label>
                                <Form.Control type="number" value={amount} onChange={(e) => setAmount(e.target.value)} placeholder="Enter Price" />
                            </Form.Group>
                            <div className='medium-space'></div>
                            <Form.Group controlId="formBasicDuration">
                                <Form.Label><i className='fa fa-clock'></i> duration</Form.Label>
                                <select className='form-control' onChange={(e) => setDuration(e.target.value)}>
                                    <option>Select Duration</option>
                                    <option value={1}>1 Month</option>
                                    <option value={2}>2 Months</option>
                                    <option value={3}>3 Months</option>
                                    <option value={4}>4 Months</option>
                                    <option value={5}>5 Months</option>
                                    <option value={6}>6 Months</option>
                                    <option value={7}>7 Months</option>
                                    <option value={8}>8 Months</option>
                                    <option value={9}>9 Months</option>
                                    <option value={10}>10 Months</option>
                                    <option value={11}>11 Months</option>
                                    <option value={12}>1 Year</option>
                                </select>
                            </Form.Group>
                            <div style={{ textAlign: 'center', padding: 15 }}><Button variant="primary" className='bbtn' type="button" onClick={() => handleSubmit()} disabled={isButtonClicked}>
                                Process
                            </Button></div>
                        </Form>
                    </div>
                    <div className='col-md-8 col-sm-6 col-xs-12'>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Duration</th>
                                    <th>Price</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ? <div>Loading...</div> : dash.map((item) => (
                                    <tr key={item._id}>
                                        <td>{item.label}</td>
                                        <td><i className='fa fa-dollar-sign'></i>{item.value}</td>
                                        <td><span className='butn' style={{ cursor: 'pointer' }} onClick={() => deletePrice(item._id)}>Delete</span></td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Prices