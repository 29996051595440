import React, { useEffect, useState } from 'react'
import Sidebar from './components/Sidebar'
import Connect from './components/Connect'
import { useNavigate } from 'react-router-dom'
import { Table } from 'react-bootstrap'
import { toast } from 'react-toastify';

const Trade = () => {
    const history = useNavigate()
    //const [dash, setDash] = useState()
    const [isLoading, setIsloading] = useState(true)
    const [trades, setTrades] = useState([])
    const [symbol, setSymbol] = useState('')
    const [stop, setStop] = useState(0)
    const [trail, setTrail] = useState(0)
    const [active, setActive] = useState(false)
    const [api, setApi] = useState('')
    const [secret, setSecret] = useState('')
    const [interval, setIntervals] = useState(1)
    const [adx, setAdx] = useState(0)
    const [ma200, setMa200] = useState(0)
    const [ma50, setMa50] = useState(0)
    const [ma25, setMa25] = useState(0)
    const [rsi, setRsi] = useState(0)
    const [stoploss, setStoploss] = useState(1)
    const [trailing, setTrailing] = useState(1)
    const [mbot, setMBot] = useState()
    const [power, setPower] = useState(false)
    const [mpair, setmPair] = useState('')
    const [gpair, setgPair] = useState([])

    const pairs = [
        'BNBUSDT', 'ETHUSDT', 'RUNEUSDT', 'AAVEUSDT', 'LTCUSDT', 'NOTUSDT', 'TONUSDT', 'SOLUSDT', 'ENAUSDT', 'PEOPLEUSDT', 'WUSDT'
    ];
    useEffect(() => {
        const exit = () => {
            const id = localStorage.getItem('daimo_admin')
            if (!id) {
                history('/')
            }
        }
        exit()
        getBot()
        getPairs()
        const getTrades = async () => {
            try {
                let id = localStorage.getItem('daimo_admin')
                id = '667f1b812f8327a1c6802e16'
                const req = await Connect.getUrl(`https://bot.daimo.bot/api/manual-trade/v2/${id}`,)
                if (req.status === 504) {
                    history('/')
                }
                if (req === 'ERR_BAD_RESPONSE') {
                    history('/')
                }
                const res = req.data;
                setTrades(res.trades)
                if (res.resCode === 104) {
                    toast(res.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    //history('/dashboard')
                } else if (res.resCode === 200) {
                    setIsloading(false)
                }
            } catch (err) {
                console.log(err.message)
            }
        }
        getTrades()
    }, [history])
    const takeTrade = async () => {
        try {
            setActive(true)
            const data = {
                symbol,
                stop
            }
            const req = await Connect.postUrl(`https://bot.daimo.bot/api/manual-trade/v5`, data)
            if (req) {
                const res = req.data
                toast(res.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setActive(false)
            getTrade()
        } catch (err) {
            toast(err.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setActive(false)
        }
    }
    const getTrade = async () => {
        try {
            setIsloading(true)
            let id = localStorage.getItem('daimo_admin')
            id = '667f1b812f8327a1c6802e16'
            console.log(id)
            const req = await Connect.getUrl(`https://bot.daimo.bot/api/manual-trade/v2/${id}`,)
            if (req.status === 504) {
                history('/')
            }
            if (req === 'ERR_BAD_RESPONSE') {
                history('/')
            }
            const res = req.data;
            setTrades(res.trades)
            if (res.resCode === 104) {
                toast(res.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                //history('/dashboard')
            } else if (res.resCode === 200) {
                setIsloading(false)
            }
        } catch (err) {
            console.log(err.message)
        }
    }
    const closeTrade = async (props) => {
        try {
            setActive(true)
            const { orderId, symbol } = props
            const data = {
                symbol,
                orderId
            }
            const req = await Connect.postUrl(`https://bot.daimo.bot/api/manual-trade/v4`, data)
            if (req) {
                const res = req.data
                toast(res.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                await getTrade()
            }
            setActive(false)
        } catch (err) {
            toast(err.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setActive(false)
        }
    }
    const SLUpdate = async (props) => {
        try {
            setActive(true)
            const { orderId, symbol } = props
            const data = {
                symbol,
                orderId,
                trail
            }
            const req = await Connect.postUrl(`https://bot.daimo.bot/api/manual-trade/v3`, data)
            if (req) {
                const res = req.data
                toast(res.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                await getTrade()
            }
            setActive(false)
        } catch (err) {
            toast(err.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setActive(false)
        }
    }
    const setBot = async () => {
        try {
            setActive(true)
            const data = {
                api,
                secret,
                interval,
                adx,
                ma200,
                ma50,
                ma25,
                rsi,
                stop: stoploss,
                trailing,
            }
            const req = await Connect.post(`/admin/bot/set`, data)
            if (req) {
                const res = req.data
                toast(res.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setActive(false)
            getBot()
        } catch (err) {
            toast(err.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setActive(false)
        }
    }
    const getBot = async () => {
        try {
            const req = await Connect.get(`/admin/bot/get`,)
            if (req.status === 504) {
                history('/')
            }
            if (req === 'ERR_BAD_RESPONSE') {
                history('/')
            }
            const res = req.data;
            setMBot(res.bot)
            setApi(res.bot.api)
            setSecret(res.bot.secret)
            setAdx(res.bot.adx)
            setIntervals(res.bot.interval)
            setRsi(res.bot.rsi)
            setMa200(res.bot.ma200)
            setMa50(res.bot.ma50)
            setMa25(res.bot.ma25)
            setStoploss(res.bot.stop)
            setTrailing(res.bot.trailing)
            setPower(res.bot.power)
        } catch (err) {
            console.log(err.message)
        }
    }

    const toggleButton = async () => {
        try {
            const data = {
                power: !power
            }
            const req = await Connect.post(`/admin/bot/power`, data)
            if (req.status === 504) {
                history('/')
            }
            if (req === 'ERR_BAD_RESPONSE') {
                history('/')
            }
            const res = req.data;
            setPower(res.power);
            toast(res.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (err) {
            console.log(err.message)
        }
    };
    const postPair = async () => {
        try {
            const data = {
                pair: mpair
            }
            const req = await Connect.post(`/admin/pair/new`, data)
            if (req.status === 504) {
                history('/')
            }
            if (req === 'ERR_BAD_RESPONSE') {
                history('/')
            }
            const res = req.data;
            setmPair('')
            setPower(res.power);
            getPairs()
            toast(res.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (err) {
            console.log(err.message)
        }
    };
    const getPairs = async () => {
        try {
            const data = {
                pair: mpair
            }
            const req = await Connect.get(`/admin/pair/get`, data)
            if (req.status === 504) {
                history('/')
            }
            if (req === 'ERR_BAD_RESPONSE') {
                history('/')
            }
            const res = req.data;
            setgPair(res.pairs);
        } catch (err) {
            console.log(err.message)
        }
    };
    const deletePair = async (id) => {
        try {
            const req = await Connect.get(`/admin/pair/delete/${id}`)
            if (req.status === 504) {
                history('/')
            }
            if (req === 'ERR_BAD_RESPONSE') {
                history('/')
            }
            const res = req.data;
            toast(res.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            getPairs()
        } catch (err) {
            console.log(err.message)
        }
    };
    return (
        <div className='containers'>
            <Sidebar />
            <div className='main'>
                <div className='titles'>Trading Historical</div>
                <div className='medium-space'></div>
                <div className='medium-space'></div>
                <div className='row'>
                    <div className='col-md-4 col-sm-6 col-xs-12'>
                        <label>SYMBOL</label>
                        <input className='form-control upper'  onChange={(e) => setSymbol(e.target.value)} value={symbol} />
                        <label>SL</label>
                        <input type='number' value={stop} onChange={(e) => setStop(e.target.value)} className='form-control' />
                        <div className='space'></div>
                        <button type='button' disabled={active} variant="primary" className='btn butn' onClick={() => takeTrade()}>Take Trade</button>
                    </div>
                    <div className='col-md-8 col-sm-6 col-xs-12'>
                        {isLoading ? <div style={{ padding: 20, textAlign: 'center' }}>Loading ...</div> :
                            <div>
                                <Table>
                                    <tr>
                                        <th>SYMBOL</th>
                                        <th>ENTRY</th>
                                        <th>SL</th>
                                        <th>SL UPDATE</th>
                                        <th>ACTION</th>
                                    </tr>
                                    {trades.map((trade) => (
                                        <tr key={trade._id}>
                                            <td>{trade.symbol}</td>
                                            <td>{trade.entry}</td>
                                            <td>{trade.stop}</td>
                                            <td>
                                                <input type='number' className='form-control' value={trail} onChange={(e) => setTrail(e.target.value)} /><span class="butn" style={{ cursor: 'pointer' }} onClick={() => SLUpdate({ orderId: trade.orderId, symbol: trade.symbol })}>Update</span>
                                            </td>
                                            <td onClick={() => closeTrade({ orderId: trade.orderId, symbol: trade.symbol })}><span class="butn" style={{ cursor: 'pointer' }} disabled={active}>Close</span></td>
                                        </tr>
                                    ))}
                                </Table>
                            </div>}
                    </div>
                </div>
                <div className='medium-space'></div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='titles'>Bot Pairs</div>
                        <div className='medium-space'></div>
                        <div class="d-flex mb-4">
                            <div data-mdb-input-init className="form-outline me-3">
                                <input type="text" id="form1" value={mpair} onChange={(e) => setmPair(e.target.value)} placeholder='pair symbol?' class="form-control upper" />
                            </div>
                            <button type="button" className="btn butn" onClick={() => postPair()}>Add</button>
                        </div>
                        {isLoading ? <p>Loading</p> : gpair.map((pair) => (<span key={pair._id} className='pair'>{pair.pair} <i className='closex' title='delete' onClick={() => deletePair(pair._id)}>x</i></span>))}
                        <div className='medium-space'></div>
                    </div>
                    <div className='col-md-12 col-sm-12 col-xs-12'>
                    </div>
                    <div className='col-md-12 col-sm-12 col-xs-12'>
                        <div className='titles'>Bot Settings</div>
                        <div className='medium-space'></div>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>API</th>
                                    <th>Secret</th>
                                    <th>ADX</th>
                                    <th>RSI</th>
                                    <th>200SMA</th>
                                    <th>50SMA</th>
                                    <th>25SMA</th>
                                    <th>SL</th>
                                    <th>Trailing</th>
                                    <th>Power</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{mbot?.api}</td>
                                    <td>{mbot?.secret}</td>
                                    <td>{mbot?.adx}</td>
                                    <td>{mbot?.rsi}</td>
                                    <td>{mbot?.ma200}</td>
                                    <td>{mbot?.ma50}</td>
                                    <td>{mbot?.ma25}</td>
                                    <td>{mbot?.stop}</td>
                                    <td>{mbot?.trailing}</td>
                                    <td>
                                        <button onClick={toggleButton} style={{
                                            padding: '5px 20px',
                                            fontSize: '16px',
                                            backgroundColor: power ? 'green' : 'red',
                                            color: 'white',
                                            border: 'none',
                                            borderRadius: '5px',
                                            cursor: 'pointer'
                                        }}>
                                            {power ? 'On' : 'Off'}
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className='col-md-12 col-sm-12 col-xs-12'>
                        <div className='titles'>Bot Updates</div>
                        <div className='space'></div>
                    </div>
                    <div className='col-md-6 col-sm-12 col-xs-12'><label>API</label>
                        <input className='form-control' onChange={(e) => setApi(e.target.value)} value={api} />
                        <div className='space'></div></div>
                    <div className='col-md-6 col-sm-12 col-xs-12'><label>SECRET</label>
                        <input className='form-control' onChange={(e) => setSecret(e.target.value)} value={secret} />
                        <div className='space'></div></div>
                    <div className='col-md-6 col-sm-12 col-xs-12'><label>Timeframe(Minutes)</label>
                        <input className='form-control' onChange={(e) => setIntervals(e.target.value)} value={interval} />
                        <div className='space'></div></div>
                    <div className='col-md-6 col-sm-12 col-xs-12'><label>ADX</label>
                        <input className='form-control' onChange={(e) => setAdx(e.target.value)} value={adx} />
                        <div className='space'></div></div>
                    <div className='col-md-6 col-sm-12 col-xs-12'><label>200 SMA</label>
                        <input className='form-control' onChange={(e) => setMa200(e.target.value)} value={ma200} />
                        <div className='space'></div></div>
                    <div className='col-md-6 col-sm-12 col-xs-12'><label>50 SMA</label>
                        <input className='form-control' onChange={(e) => setMa50(e.target.value)} value={ma50} />
                        <div className='space'></div></div>
                    <div className='col-md-6 col-sm-12 col-xs-12'><label>25 SMA</label>
                        <input className='form-control' onChange={(e) => setMa25(e.target.value)} value={ma25} />
                        <div className='space'></div></div>
                    <div className='col-md-6 col-sm-12 col-xs-12'><label>RSI</label>
                        <input className='form-control' onChange={(e) => setRsi(e.target.value)} value={rsi} />
                        <div className='space'></div></div>
                    <div className='col-md-6 col-sm-12 col-xs-12'><label>Stop loss(percentage)</label>
                        <input className='form-control' onChange={(e) => setStoploss(e.target.value)} value={stoploss} />
                        <div className='space'></div></div>
                    <div className='col-md-6 col-sm-12 col-xs-12'><label>Trailing Stop(percentage)</label>
                        <input className='form-control' onChange={(e) => setTrailing(e.target.value)} value={trailing} />
                        <div className='space'></div></div>

                    <button type='button' className='btn butn' onClick={() => setBot()}>Update</button>

                </div>
            </div>
        </div>
    )
}

export default Trade