import React, { useEffect, useState } from 'react'
import Sidebar from './components/Sidebar'
import Connect from './components/Connect'
import { Table } from 'react-bootstrap'

const Subscriptions = () => {
    const [dash, setDash] = useState()
    const [isLoading, setIsloading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const itemsPerPage = 20;

    const handleClick = (event) => {
        setCurrentPage(Number(event.target.id));
    };

    useEffect(() => {
        const getDashboard = async () => {
            try {
                const req = await Connect.get(`admin/subscribers`)
                const res = req.data;
                setDash(res.subscribers)
                setTotalPages(Math.ceil(res.subscribers.length / itemsPerPage));
                const indexOfLastItem = currentPage * itemsPerPage;
                const indexOfFirstItem = indexOfLastItem - itemsPerPage;
                setDash(res.subscribers.slice(indexOfFirstItem, indexOfLastItem));
                setIsloading(false)
            } catch (err) {
                console.log(err.message)
            }
        }
        getDashboard()
    }, [currentPage])
    const renderPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }

        return pageNumbers.map(number => (
            <li
                key={number}
                id={number}
                onClick={handleClick}
                style={{ cursor: 'pointer', padding: '5px', display: 'inline' }}
            >
                {number}
            </li>
        ));
    };
    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrev = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    return (
        <div className='containers'>
            <Sidebar />
            <div className='main'>
                <div className='titles'>Active Subscribers</div>
                <div className='medium-space'></div>
                <div className='medium-space'></div>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Address</th>
                            <th>Hash</th>
                            <th>Amount</th>
                            <th>Expires</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? <div>Loading ...</div> : dash.map((item, index) => (
                            <tr key={item._id}>
                                <td>{item.address}</td>
                                <td>{item.txid_in || 'N/A'}</td>
                                <td>${item.amount}</td>
                                <td>{item.expires}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <div className='medium-space'></div>
                {itemsPerPage <= 20 ? <div></div> :
                    <div className='paginate'>
                        <button onClick={handlePrev} disabled={currentPage === 1} className='butn'>
                            Previous
                        </button>
                        <ul id="page-numbers" style={{ display: 'inline' }}>
                            {renderPageNumbers()}
                        </ul>
                        <button onClick={handleNext} disabled={currentPage === totalPages} className='butn'>
                            Next
                        </button>
                    </div>}
            </div>
        </div>
    )
}

export default Subscriptions