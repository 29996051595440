import React, { useState } from 'react'
import logo from '../../assets/images/logo.png'
import { NavLink } from 'react-router-dom'

const Sidebar = () => {
    const isMobile = window.innerWidth <= 768;
    let st = isMobile ? false : true
    const [isOpen, setIsOpen] = useState(st)
    const toggle = () => setIsOpen(!isOpen)
    const menuItems = [
        {
            path: "/dashboard",
            name: "Dashboard",
            icon: <i className='fa fa-home' />
        },
        {
            path: "/users",
            name: "Users",
            icon: <i className='fa fa-users' />
        },
        {
            path: "/subscriptions",
            name: "Subscriptions",
            icon: <i className='fa fa-volume-up' />
        },
        {
            path: "/prices",
            name: "Prices",
            icon: <i className='fa fa-dollar-sign' />
        },
        {
            path: "/admins",
            name: "Admins",
            icon: <i className='fa fa-user' />
        },
        {
            path: "/logout",
            name: "Logout",
            icon: <i className='fa fa-power-off' />
        }
    ]
    return (
        <div style={{ width: isOpen ? "300px" : "50px" }} className='sidebar'>
            <div className='top_section'>
                <div style={{ display: isOpen ? "block" : "none" }} className='logo'><img src={logo} alt='Logo' /></div>
                <div style={{ marginLeft: isOpen ? "50px" : "0px", cursor: 'pointer' }} className='bars'>
                    <i className='fa fa-bars' onClick={toggle} />
                </div>
            </div>
            {menuItems.map((item, index) => (
                <NavLink to={item.path} key={index} className='link' activeClassName='active'>
                    <div className='icon'>{item.icon}</div>
                    <div style={{ display: isOpen ? "block" : "none" }} className='link_text'>{item.name}</div>
                </NavLink>
            ))}
        </div>
    )
}

export default Sidebar