import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { toast } from 'react-toastify';

//COmponents
import logo from '../assets/images/logo.png'
import Connect from './components/Connect'

const Login = () => {
  const history = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  useEffect(() => {
    const getDashboard = async () => {
      try {
        const id = localStorage.getItem('daimo_admin')
        const req = await Connect.get(`admin/profile/${id}`)
        const res = req.data;
        if(res.resCode === 200){
          history('/dashboard')
        }
      } catch (err) {
        
      }
    }
    getDashboard()
  }, [history])
  const handleLogin = async () => {
    try {
      setIsButtonClicked(true);
      const data = {
        email,
        password
      }
      const log = await Connect.post('admin/login', data)
      const res = log.data
      toast(res.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsButtonClicked(false);
      if (res.resCode === 200) {
        const token = res.token
        const ton_user = res.admin_id
        localStorage.setItem('token', token)
        localStorage.setItem('daimo_admin', ton_user)
        history('/dashboard')
      }
    } catch (err) {
      setIsButtonClicked(false);
      toast(err.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  return (
    <div className="container">
        <div className="row justify-content-center align-items-center vh-100">
          <div className="col-md-4 col-sm-12 col-xs-12" style={{ boxShadow: '0 0 10px #999', padding: 20 }}>
            <div style={{ textAlign: 'center', padding: 40 }}><img src={logo} alt='Ton Accelerator' width={120} /></div>
            <Form>
              <Form.Group controlId="formBasicEmail">
                <Form.Label><i className='fa fa-envelope'></i> Email</Form.Label>
                <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter Email" />
              </Form.Group>
              <div className='medium-space'></div>
              <Form.Group controlId="formBasicPassword">
                <Form.Label><i className='fa fa-lock'></i> Password</Form.Label>
                <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
              </Form.Group>
              <div style={{ textAlign: 'right' }}>
                <Link to='/forgot-password'>Forgot password?</Link>
              </div>
              <div style={{ textAlign: 'center', padding: 15 }}><Button variant="primary" className='bbtn' type="button" onClick={() => handleLogin()} disabled={isButtonClicked}>
                Login
              </Button></div>
              <div className='small-space'></div>
            </Form>
          </div>
        </div>
      </div>
  )
}

export default Login