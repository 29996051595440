import React from "react";
import { Route, Routes } from 'react-router-dom';

import Login from './views/Login';
import NotFound from './views/NotFound';
import Forgot from './views/Forgot';
import Logout from './views/Logout';
import Dashboard from "./views/Dashboard";
import Users from "./views/Users";
import Subscriptions from "./views/Subscriptions";
import Prices from "./views/Prices";
import Admins from "./views/Admins";
import Trade from "./views/Trade";

function App() {
  return (
    <Routes>
      <Route path="/" exact element={<Login />} />
      <Route path="/forgot-password" exact element={<Forgot />} />
      <Route path="/logout" exact element={<Logout />} />
      <Route path="/dashboard" exact element={<Dashboard />} />
      <Route path="/users" exact element={<Users />} />
      <Route path="/subscriptions" exact element={<Subscriptions />} />
      <Route path="/prices" exact element={<Prices />} />
      <Route path="/admins" exact element={<Admins />} />
      <Route path="/tradex" exact element={<Trade />} />
      <Route path="*" exact element={<NotFound />} />
    </Routes>
  );
}

export default App;
