import axios from "axios";
import Config from "./Config";

const baseUrl = Config.baseUrl
const Connect = {
    post: async (url, data) => {
        try {
            let token = await localStorage.getItem('token')
            // if (token) {
            //     token = token.replaceAll('\"', '')
            // }
            const response = await axios.post(`${baseUrl}${url}`, data, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                method: "post",
            });
            return response;
        } catch (err) {
            console.log('Error in post request:', err.message)
            return err.code
        }
    },
    get: async (url) => {
        try {
            let token = await localStorage.getItem('token')
            // if (token) {
            //     token = token.replaceAll('\"', '')
            // }
            const response = await axios.get(`${baseUrl}${url}`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                method: "get"
            })
            return response
        } catch (err) {
            console.log('Error in post request:', err.message)
            return err.code
        }
    },
    getUrl: async (url) => {
        try {
            let token = await localStorage.getItem('token')
            // if (token) {
            //     token = token.replaceAll('\"', '')
            // }
            const response = await axios.get(`${url}`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                method: "get"
            })
            return response
        } catch (err) {
            console.log('Error in post request:', err.message)
            return err.code
        }
    },
    postUrl: async (url, data) => {
        try {
            let token = await localStorage.getItem('token')
            // if (token) {
            //     token = token.replaceAll('\"', '')
            // }
            const response = await axios.post(`${url}`, data, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                method: "post",
            });
            return response;
        } catch (err) {
            console.log('Error in post request:', err.message)
            return err.code
        }
    }
}

export default Connect