import React, { useEffect, useState } from 'react'
import Sidebar from './components/Sidebar'
import Connect from './components/Connect'
import { Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Table } from 'react-bootstrap'

//const { format, isThisWeek, isToday, isYesterday } = require('date-fns');

const Dashboard = () => {
  const history = useNavigate()
  //const [dash, setDash] = useState()
  const [isLoading, setIsloading] = useState(true)
  const [funds, setFunds] = useState(false)
  const [allUsers, setAllUsers] = useState()
  const [subscriptions, setSubscription] = useState()

  //   const formatDate = (inputDate) => {
  //     //inputDate = inputDate.toString()
  //     const date = new Date(inputDate);

  //     if (isToday(date)) {
  //         return 'Today';
  //     }

  //     if (isYesterday(date)) {
  //         return 'Yesterday';
  //     }

  //     if (isThisWeek(date, { weekStartsOn: 1 })) {
  //         return format(date, 'EEEE'); // Returns day of the week
  //     }

  //     // If more than a week ago, return the formatted date as 'YYYY-MM-DD'
  //     return format(date, 'yyyy-MM-dd');
  // }
  useEffect(() => {
    const exit = () => {
      const id = localStorage.getItem('daimo_admin')
      if (!id) {
        history('/')
      }
    }
    exit()
    const getDashboard = async () => {
      try {
        let id = localStorage.getItem('daimo_admin')
        const req = await Connect.get(`admin/dashboard/${id}`)
        if (req.status === 504) {
          history('/')
        }
        if (req === 'ERR_BAD_RESPONSE') {
          history('/')
        }
        const res = req.data;
        console.log(res)
        setAllUsers(res.users)
        //setDash(res.user)
        setSubscription(res.subscription)
        setFunds(res.funds)
        setIsloading(false)
      } catch (err) {
        console.log(err.message)
      }
    }
    getDashboard()
  }, [history])
  return (
    <div className='containers'>
      <Sidebar />
      <div className='main'>
        <Row>
          <div className='col-md-3 col-sm-6 col-xs-12'>
            <div className='ball'>
              <div className='ball-head'>Users</div>
              <div className='ball-content'><i className='fa fa-users' /> {isLoading ? '--' : allUsers.total}</div>
            </div>
            <div className='spacex'></div>
          </div>
          <div className='col-md-3 col-sm-6 col-xs-12'>
            <div className='ball'>
              <div className='ball-head'>Active Subscription</div>
              <div className='ball-content'><i className='fa fa-users' /> {isLoading ? '--' : subscriptions.active}</div>
            </div>
            <div className='spacex'></div>
          </div>
          <div className='col-md-3 col-sm-6 col-xs-12'>
            <div className='ball'>
              <div className='ball-head'>Expired Subscriptions</div>
              <div className='ball-content'><i className='fa fa-coins' /> {isLoading ? '--' : subscriptions.expired}</div>
            </div>
            <div className='spacex'></div>
          </div>
          <div className='col-md-3 col-sm-6 col-xs-12'>
            <div className='ball'>
              <div className='ball-head'>All Time Subscriptions</div>
              <div className='ball-content'><i className='fa fa-coins' /> {isLoading ? '--' : subscriptions.active + subscriptions.expired}</div>
            </div>
          </div>
        </Row>
        <div className='medium-space'></div>
        <div className='medium-space'></div>
        <div className='medium-space'></div>
        <div className='titles'>Recent Subscriptions</div>
        <div className='medium-space'></div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <td>Address</td>
              <td>Hash</td>
              <td>Amount</td>
              <td>Expires</td>
            </tr>
          </thead>
          <tbody>
            {isLoading ? <tr><td>Loading...</td></tr> : funds.recent.map((item, index) => (
              <tr key={index}>
                <td>{item.address}</td>
                <td>{item.txid_in || 'N/A'}</td>
                <td>${item.amount}</td>
                <td>{item.expires}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  )
}


export default Dashboard